import { LightboxActionContainerBlock, Button } from '..';
import type { Receipt } from '../../interfaces';

type ComponentProps = {
  statusCode: number,
  receipt: Receipt,
  download: () => void
  close: () => void
};

export default function LightboxReceiptDownload({ statusCode, receipt, download, close }: ComponentProps) {
  const getText = (textType: string, statusCode: number) => {
    if (textType === 'header') {
      switch(statusCode) {
        default: return 'Download receipt';
      }
    } else if (textType === 'instructions') {
      switch(statusCode) {
        case 102: return 'Downloading, please wait.';
        case 200: return 'Receipt ready to download.';
        case 404: return 'No such receipt.';
        case 500: return 'Unable to download. Please try again.';
        default: return 'Downloading, please wait.';
      }
    }

    return '';
  };

  return (
    <LightboxActionContainerBlock 
      header={getText('header', statusCode)}
      instructions={getText('instructions', statusCode)} 
      loading={[102].indexOf(statusCode) >= 0 ? true : false}
      receipt={receipt} close={close} >
      
      {[102, 200].indexOf(statusCode) < 0 ? (
        <Button className="bg-purple" text="Okay" onClick={close} />
      ) : (null)}

      {[200].indexOf(statusCode) >= 0 ? (
        <div className="flex">
          <div className="w-6/12 pe-2">
            <Button className="bg-purple" text="Download" onClick={download} />
          </div>
          <div className="w-6/12 ps-2">
            <Button className="bg-[#a6a6a6]" text="Close" onClick={close} />
          </div>
        </div>
      ) : (null)}
    </LightboxActionContainerBlock>
  );
};
