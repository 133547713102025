import { useState, useEffect } from 'react';
import { Button, Logo, VerificationText } from '.';
import { OTP_REQUEST_AGAIN_TIMEOUT, OTP_REQUEST_AGAIN_ALLOWED } from '../utils/constants';
import { format_mobile } from '../utils/formatters';

type ComponentProps = {
  phoneNumber: string,
  otp: string,
  setOtp: any,
  errorCode : number,
  otpLastRequest: number,
  requestToken: () => void,
  requestOtp: () => void
};

export default function LoginOtp({ phoneNumber, otp, setOtp, errorCode, otpLastRequest, requestToken, requestOtp }: ComponentProps) {
  const [allowOtpResend, setAllowOtpResend] = useState(false);

  useEffect(() => { 
    const recheck = setInterval(() => { 
      if (Date.now() - otpLastRequest > (OTP_REQUEST_AGAIN_TIMEOUT * 1000)) {
        setAllowOtpResend(true);
      } else {
        setAllowOtpResend(false);
      }
    }, OTP_REQUEST_AGAIN_ALLOWED * 3000); 

    return () => clearInterval(recheck); 
  }, [otpLastRequest]);

  return (<>
    <div className=" min-h-[calc(100vh-140px)] md:min-h-[calc(100vh-110px)] flex items-center justify-center">
      <div className="text-center">
        <Logo />
        <VerificationText text="SMS verification code" />
        <p className="md:text-2xl text-cyan mb-2.5">{format_mobile(phoneNumber)}</p>
        <div className="phone-inp">
          <input type="tel" className='w-full text-center' value={otp} onChange={e => setOtp(e.target.value)} />
        </div>
        {[400, 401].indexOf(errorCode) >= 0 ? <p className="text-xs md:text-lg text-error mt-4">Invalid OTP number.</p> : null}
        {[429].indexOf(errorCode) >= 0 ? <p className="text-xs md:text-lg text-error mt-4">OTP already sent. Please try again later.</p> : null}
        {[0, 102, 200, 400, 401, 429].indexOf(errorCode) < 0 ? <p className="text-xs md:text-lg text-error mt-4">An error has occurred please try again later.</p> : null}
        <p className="text-xs md:text-lg text-white mt-2 max-w-lg mx-auto">Please enter the verification code received by SMS.</p>

        {allowOtpResend ? (
          <div className="resend">
            <p className="text-sm text-white mt-8">
              If you did not receive the verification code. <br/>
              <button type="button" className="text-cyan underline mt-2 py-2 text-sm md:text-lg" onClick={() => { setAllowOtpResend(false); requestOtp()} }>Resend Code</button>
            </p>
          </div>
         ) : (<div className="py-11"></div>)}

        <div className="mt-4">
          <span className={[102].indexOf(errorCode) >= 0 ? 'cus-loader' : 'cus-loader-holder'}></span>
        </div>
      </div>      
    </div>
    <div className="bottom-div absolute left-0 bottom-[100px] md:bottom-6 w-full">
      <div className="max-w-screen-md mx-auto px-4">
        <Button className="bg-purple" text="Next" onClick={requestToken} />
      </div>
    </div>
  </>);
};
