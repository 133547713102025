import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageTitle, ReceiptDetails, LightboxReceiptRemove, LightboxReceiptDownload } from '../components';
import { GetReceipt, DeleteReceipt, DownloadReceipt } from '../graphql';
import { useGraphQLQuery, useGraphQLMutation } from '../hooks';
import { ReceiptLoading } from '../interfaces';
import { format_url, format_reseed_domain_url } from '../utils/formatters';

export default function Receipt() {
  const [actionType, setActionType] = useState('');
  const [actionList, setActionList] = useState<any>({
    list: () => navigate(format_url(['/receipts'])),
    remove: () => ConfirmDoMutationDeleteReceipt()
  });
  const [modal, setModal] = useState('');
  const [modalStatusCode, setModalStatusCode] = useState(0);
  const [downloadLink, setDownloadLink] = useState('');

  const navigate = useNavigate();
  const { receiptId } = useParams();

  /* DeleteReceipt mutation */
  const [MutationDeleteReceiptFunction, { data: MutationDeleteReceiptData }] = useGraphQLMutation(DeleteReceipt);
  
  const DoMutationDeleteReceipt = () => {
    setModalStatusCode(102);
    MutationDeleteReceiptFunction({
      variables: { ReceiptId: receiptId }
    });
  };

  const ConfirmDoMutationDeleteReceipt = () => {
    setModal('remove');
    setModalStatusCode(0);
  };

  useEffect(() => {
    if (MutationDeleteReceiptData && MutationDeleteReceiptData.DeleteReceipt && MutationDeleteReceiptData.DeleteReceipt.StatusCode) {
      setModalStatusCode(MutationDeleteReceiptData.DeleteReceipt.StatusCode);
      navigate(format_url(['/receipts']), { replace: true })
    }
  }, [MutationDeleteReceiptData, navigate]);

  /* DownloadReceipt mutation */
  const [MutationDownloadReceiptFunction, { data: MutationDownloadReceiptData }] = useGraphQLMutation(DownloadReceipt);
  
  const DoMutationDownloadReceipt = useCallback(() => {
    setModal('download');
    setModalStatusCode(102);
    MutationDownloadReceiptFunction({
      variables: { ReceiptId: receiptId }
    });
  }, [receiptId, MutationDownloadReceiptFunction]);

  useEffect(() => {
    if (MutationDownloadReceiptData && MutationDownloadReceiptData.DownloadReceipt && MutationDownloadReceiptData.DownloadReceipt.StatusCode) {
      setModalStatusCode(MutationDownloadReceiptData.DownloadReceipt.StatusCode);
      if (MutationDownloadReceiptData.DownloadReceipt.Id) {
        setDownloadLink(MutationDownloadReceiptData.DownloadReceipt.Id);
      }
    }
  }, [MutationDownloadReceiptData]);

  /* Functions */
  const ConfirmMutationDownloadReceipt = useCallback(() => {
    if (['pdf', 'png'].indexOf(downloadLink) >= 0) {
      DoMutationDownloadReceipt();
    } else if (downloadLink.length) {
      setModalStatusCode(0);
      window.open(format_reseed_domain_url('downloads', [downloadLink]), '_blank', 'noreferrer');
    }
  }, [downloadLink, DoMutationDownloadReceipt]);

  /* GetReceipt query */
  const {
    loading: QueryGetReceiptLoading, // eslint-disable-line @typescript-eslint/no-unused-vars
    data: QueryGetReceiptData
  } = useGraphQLQuery(GetReceipt, { variables: { ReceiptId: receiptId }});

  useEffect(() => {
    if (QueryGetReceiptData && QueryGetReceiptData.GetReceipt) {
      if (!QueryGetReceiptData.GetReceipt.ReceiptId) {
        navigate(format_url(['/receipts']), { replace: true })
      } else {
        setActionType('view');
        if (QueryGetReceiptData.GetReceipt.Meta) {
          const meta = JSON.parse(QueryGetReceiptData.GetReceipt.Meta);
          if (meta && meta.Download && meta.Download.length) {
            setDownloadLink((downloadLink) => ['', 'pdf', 'png'].indexOf(downloadLink) >= 0 ? meta.Download : downloadLink);
            setActionList({
              download: () => ConfirmMutationDownloadReceipt(),
              list: () => navigate(format_url(['/receipts'])),
              remove: () => ConfirmDoMutationDeleteReceipt()
            });    
          }
        }  
      }
    }
  }, [QueryGetReceiptData, ConfirmMutationDownloadReceipt, navigate]);

  /* Render */
  return (<>  
    <PageTitle title="YOUR RECEIPT" />

    {modal === 'remove' ? (
      <LightboxReceiptRemove statusCode={modalStatusCode} receipt={QueryGetReceiptData.GetReceipt} remove={DoMutationDeleteReceipt} close={() => setModal('')} />
    ) : (null)}
    {modal === 'download' ? (
      <LightboxReceiptDownload statusCode={modalStatusCode} receipt={QueryGetReceiptData.GetReceipt} download={ConfirmMutationDownloadReceipt} close={() => setModal('')} />
    ) : (null)}
    
    {QueryGetReceiptData && QueryGetReceiptData.GetReceipt && QueryGetReceiptData.GetReceipt.ReceiptId ? (
      <ReceiptDetails receipt={QueryGetReceiptData.GetReceipt} actionType={actionType} actionList={actionList} />
    ) : (
      <ReceiptDetails receipt={ReceiptLoading} actionType={''} actionList={{}} />
    )}
  </>);
};
