import { Image } from '..';

type ComponentProps = {
  className : string,
  text : string,
  imgSrc: any,
  onClick : () => void,
};

export default function IconButton({className, text, imgSrc, onClick} : ComponentProps) {
  return (
    <button className={`text-sm md:text-lg uppercase font-semibold hover:bg-cyan transition-all duration-300 hover:duration-300 hover:transition-all block text-white w-full rounded-lg py-2 px-4 ${className}`} onClick={onClick}>
      <Image
          src={imgSrc}
          alt="Arrow Icon"
          width={10}
          height={13}
          className="inline-block me-2"
        />
      {text}
    </button>
  );
};
