import { gql } from '@apollo/client';

const mutation = gql`
  mutation mutation($ReceiptId: ID!) {
    DeleteReceipt(ReceiptId: $ReceiptId) {
      Status
      StatusCode
      Message
      Id
      Data
    }
  }
`;

export default mutation;
