import { BodyText } from '..';

type ComponentProps = {
  table: string,
  loading: boolean,
};

export default function TableInfoBlock({ table, loading }: ComponentProps) {
  return (
    <div className="flex justify-between items-center">
      <BodyText text={''} variant="receipt-text" loading={false} loadingClasses={'w-36'} />
      <BodyText text={ loading ? '' : ['Table:', table].join(' ')} variant="receipt-text" loading={loading} loadingClasses={'w-24'} />
    </div>
  );
};
