import { BodyText } from '..';
import type { CustomerInfo } from '../../interfaces';

type ComponentProps = {
  customer: CustomerInfo,
  loading: boolean,
  theme?: string
};

export default function CustomerInfoBlock({ customer, loading, theme = 'light' }: ComponentProps) {
  return (
    <div className="mt-3 mb-6">
      {customer.Name ? (
        <div className="flex justify-between items-center mt-1.5">
          <BodyText text={'Customer'} variant={theme === 'dark' ? 'receipt-text-light' : 'receipt-text'} loading={false} />
          <BodyText text={customer.Name} variant={theme === 'dark' ? 'receipt-text-light' : 'receipt-text'} loading={loading} loadingClasses={'w-12'} />
        </div>
      ) : (null)}
      {customer.Mobile ? (
        <div className="flex justify-between items-center mt-1.5">
          <BodyText text={'Mobile'} variant={theme === 'dark' ? 'receipt-text-light' : 'receipt-text'} loading={false} />
          <BodyText text={customer.Mobile} variant={theme === 'dark' ? 'receipt-text-light' : 'receipt-text'} loading={loading} loadingClasses={'w-12'} />
        </div>
      ) : (null)}
    </div>
  );
};
