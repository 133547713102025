import { BodyText } from '..';
import type { ReceiptItem } from '../../interfaces';
import { format_currency } from '../../utils/formatters';

type ComponentProps = {
  currency: string,
  items: ReceiptItem[],
  loading: boolean,
};

export default function ReceiptItemsBlock({ currency, items, loading }: ComponentProps) {
  if (loading) {
    currency = 'SGD';
    items = [
      { Quantity: 0, Name: '', Description: '', Total: 0 },
      { Quantity: 0, Name: '', Description: '', Total: 0 },
      { Quantity: 0, Name: '', Description: '', Total: 0 },
      { Quantity: 0, Name: '', Description: '', Total: 0 },
    ];
  }

  return (
    <div className="border border-solid border-gray pb-4 px-4 mb-4">
      {items.map((item, idx) => (
        <div key={idx} className="flex justify-between items-center mt-4">
          <div className="flex items-center">
            {item.ItemNumber ? (
              <BodyText text={[item.ItemNumber, '.'].join('')} variant="receipt-item-quantity" loading={loading} loadingClasses={'w-8'} />
            ) : (
              <BodyText text={item.Quantity === 0 ? '' : [item.Quantity, 'x'].join('')} variant="receipt-item-quantity" loading={loading} loadingClasses={'w-8'} />
            )}            
            <BodyText text={item.Name} variant="receipt-text" loading={loading} loadingClasses={'w-48'} />
          </div>
          <BodyText text={format_currency(item.Total, currency)} variant="receipt-text" loading={loading} loadingClasses={'w-12'} />
        </div>
      ))}
    </div>
  );
};
