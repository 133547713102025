import { ReceiptMetaBlock, OutletInfoBlock, CustomerInfoBlock, ReceiptItemsBlock, ReceiptTabulationBlock, OutletLocationBlock, ActionsBlock, TableInfoBlock } from '..';
import type { Receipt } from '../../interfaces';

type ComponentProps = {
  receipt: Receipt,
  actionType: string,
  actionList: any
};

export default function ReceiptDetails({ receipt, actionType, actionList }: ComponentProps) {
  const meta = JSON.parse(receipt.Meta);
  const items = JSON.parse(receipt.Items);
  const tabulation = JSON.parse(receipt.Tabulation);
  const payment = JSON.parse(receipt.Payment);
  const receipt_loading = receipt.ReceiptId === '';
  const store_loading = receipt.StoreName === '';
  
  return (
    <main className="pt-6 pb-[110px] md:pb-[150px]">
      <ReceiptMetaBlock date={meta.Datetime.Formatted} id={meta.Id} loading={receipt_loading} />
      
      {meta && meta.Table ? (
        <TableInfoBlock table={meta.Table} loading={store_loading} />
      ) : (null)}

      <OutletInfoBlock name={receipt.StoreName} location={receipt.LocationName} logo={receipt.Logo} loading={store_loading} />

      {meta && meta.Customer ? (
        <CustomerInfoBlock customer={meta.Customer} loading={store_loading} />
      ) : (null)}

      <ReceiptItemsBlock currency={receipt.Currency} items={items} loading={receipt_loading} />
      <ReceiptTabulationBlock currency={receipt.Currency} tabulation={tabulation} payment={payment} loading={receipt_loading} />
      <OutletLocationBlock address={receipt.Address} googleMap={receipt.GoogleMap} loading={store_loading} />
      {receipt_loading ? (
        ''
      ) : (
        <ActionsBlock actionType={actionType} actionList={actionList} />
      )}
    </main>
  );
};
