import { useState, useEffect } from 'react';
import { PageTitle, ProfileForm } from '../components';
import { GetProfile, UpdateProfile } from '../graphql';
import { useGraphQLQuery, useGraphQLMutation } from '../hooks';
import type { InputProfile } from '../interfaces';

export default function Profile() {
  const [errorCode, setErrorCode] = useState(0);

  /* GetProfile query */
  const { loading: QueryGetProfileLoading, data: QueryGetProfileData, refetch: QueryGetProfileRefetch } = useGraphQLQuery(GetProfile);

  /* UpdateProfile mutation */
  const [MutationUpdateProfileFunction, { loading: MutationUpdateProfileLoading, data: MutationUpdateProfileData }] = useGraphQLMutation(UpdateProfile);
  
  const DoMutationUpdateProfile = (profile: InputProfile) => {
    MutationUpdateProfileFunction({
      variables: { Profile: profile }
    });
  };

  useEffect(() => {
    if (MutationUpdateProfileData && MutationUpdateProfileData.UpdateProfile && MutationUpdateProfileData.UpdateProfile.StatusCode) {
      setErrorCode(MutationUpdateProfileData.UpdateProfile.StatusCode);
      switch(MutationUpdateProfileData.UpdateProfile.StatusCode) {
        case 200:
          QueryGetProfileRefetch();
          break;
        case 404:
        case 400:
        default:
          setErrorCode(400);
          break;
      }
    }
  }, [MutationUpdateProfileData, QueryGetProfileRefetch]);

  /* Render */
  return (<>  
    <PageTitle title="YOUR PROFILE" />

    {QueryGetProfileLoading && errorCode !== 200 ? (
      <div className="mt-8 text-center">
        <span className="cus-loader"></span>
      </div>
    ) : (
      <ProfileForm profile={QueryGetProfileData && QueryGetProfileData.GetProfile ? QueryGetProfileData.GetProfile : {}} saveProfile={DoMutationUpdateProfile} loading={MutationUpdateProfileLoading} errorCode={errorCode} />
    )}
  </>);
};
