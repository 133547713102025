import { CURRENCIES, MOBILE_FORMATS } from './constants';

export function format_currency(amount: number, currency: string, value_only?: boolean) {
  const currency_symbol = currency.toUpperCase();
  const currency_config = CURRENCIES[currency_symbol as keyof typeof CURRENCIES];

  value_only = (((typeof(value_only) === 'undefined') || (typeof(value_only) !== 'boolean')) ? false : value_only);
  amount = typeof(amount) === 'number' ? amount : (isNaN(parseFloat(amount)) ? 0 : parseFloat(amount));
  if (value_only) {
    return amount.toFixed(currency_config['decimals']);
  } else {
    const amount_array = ('' + amount.toFixed(currency_config['decimals'])).split('.');
    let regex = new RegExp("(\\d)(?=(\\d{" + currency_config['thousands'] + "})+(?!\\d))","g");
    let integral = amount_array[0].toString().replace(regex, "$1" + currency_config['thousands_separator']);
    let result = [currency_config['prefix'], integral, currency_config['postfix']];
    if (currency_config['decimals'] > 0) {
      result.splice(2, 0, currency_config['decimal_point'], amount_array[1]);
    }
    return result.join('');
  }
};

export function format_mobile(number: string) {
  const country_codes = Object.keys(MOBILE_FORMATS);
  for (let i = 0; i < country_codes.length; i++) {
    const country_mobile_config = MOBILE_FORMATS[country_codes[i] as keyof typeof MOBILE_FORMATS];
    const idd_prefix = ['+', country_mobile_config['idd_code']].join('');
    if (number.indexOf(idd_prefix) === 0) {
      const mobile = number.substring(idd_prefix.length);
      const re = new RegExp(country_mobile_config['display']['parse'], 'g');
      if (re.test(mobile)) {
        return idd_prefix + ' ' + mobile.replace(re, country_mobile_config['display']['format']);
      }
      break;
    }
  }
  
  return '';
};

export function format_url(path: string[], query?: object) {
  let q = [];
  let fullpath = path.join('/');

  if (query) {
    for (let k in query) {
      q.push([k, query[k as keyof typeof query]].join('='));
    }
    return [fullpath, q.join('&')].join('?');
  } else {
    return fullpath;
  }
};

export function format_reseed_domain_url(host: string, path: string[], query?: object) {
  const domain = [process.env.REACT_APP_DOMAIN];
  const fullpath = [format_url(path, query)];
  
  if (host === '' || host === 'www') {
    host = '';
  }
  if (host.length) {
    domain.unshift(host);
  }
  if (fullpath[0] !== '/') {
    fullpath.unshift('');
  }

  return ['https://', domain.join('.'), fullpath.join('/')].join('');
};