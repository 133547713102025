import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { format_url } from '../utils/formatters';

export default function Logout() {
  const navigate = useNavigate();
  
  useEffect(() => {
    localStorage.setItem('AccessToken', '');
    navigate(format_url(['/']));
  }, );

  /* Render */
  return (<></>);
};
