import { gql } from '@apollo/client';

const query = gql`
  query query($DeviceId: ID!, $ReceiptId: ID!) {
    GetNewReceipt(DeviceId: $DeviceId, ReceiptId: $ReceiptId) {
      ReceiptId
      Category
      StoreName
      LocationName
      Address
      Logo
      GoogleMap {
        Embed
        Link
      }
      Currency
      Total
      Meta
      Items
      Tabulation
      Payment
      Timestamp
    }
  }
`;

export default query;
