type ComponentProps = {
  className : string,
  text : string,
  onClick : () => void,
};

export default function Button({className, text, onClick} : ComponentProps) {
  return (
    <button className={`text-sm md:text-lg uppercase font-semibold hover:bg-cyan transition-all duration-300 hover:duration-300 hover:transition-all block text-white w-full rounded-lg py-2 px-4 ${className}`} onClick={onClick}>{text}</button>
  );
};
