export const ReceiptLoading = {
  ReceiptId: '',
	Category: '',
	StoreName: '',
	LocationName: '',
	Address: '',
	Logo: '',
	GoogleMap: {
		Embed: '',
		Link: ''
	},
	Currency: '',
	Total: 0,
	Timestamp: 0,
  Meta: '{"Id":"","Table":"","Download":"pdf","Datetime":{"Formatted":"","Original":""}}',
  Items: '[]',
  Tabulation: '[]',
  Payment: '{}',
};
