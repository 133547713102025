'use client';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageTitle, Welcome } from '../components';
import { CheckIsAuthenticated } from '../graphql';
import { useGraphQLQuery } from '../hooks';
import { format_url } from '../utils/formatters';

export default function Home() {
  const navigate = useNavigate();

  /* CheckIsAuthenticated query */
  const { loading: QueryCheckIsAuthenticatedLoading, data: QueryCheckIsAuthenticatedData } = useGraphQLQuery(CheckIsAuthenticated);
  
  useEffect(() => {
    if (QueryCheckIsAuthenticatedData && QueryCheckIsAuthenticatedData.CheckIsAuthenticated && QueryCheckIsAuthenticatedData.CheckIsAuthenticated.StatusCode) {
      switch(QueryCheckIsAuthenticatedData.CheckIsAuthenticated.StatusCode) {
        case 200:
          navigate(format_url(['/receipts']));
          break;
        default:
          break;
      }
    }
  }, [QueryCheckIsAuthenticatedData, navigate]);

  /* Functions */
  const GoToLogin = () => {
    localStorage.setItem('AccessToken', '');
    navigate(format_url(['/login']));
  }

  /* Render */
  return (<>
    <PageTitle title="YOUR RECEIPTS" />

    {!QueryCheckIsAuthenticatedLoading && !QueryCheckIsAuthenticatedData ? (
      <Welcome login={GoToLogin} />
    ) : (
      <div className="mt-16 text-center">
        <span className="cus-loader"></span>
      </div>
    )}
  </>);
};
