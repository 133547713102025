import { gql } from '@apollo/client';

const query = gql`
  query query($Pagination: InputPagination) {
    ListReceipts(Pagination: $Pagination) {
      Receipts {
        ReceiptId
        Category
        StoreName
        LocationName
        Logo
        Currency
        Total
        Timestamp
      }
      Pagination {
        Filters
        SortBy
        NextToken
      }
    }
  }
`;

export default query;
