type ComponentProps = {
  src: string,
  alt: string,
  title?: string,
  width: number,
  height: number,
  className?: string,
};

export default function Image({ src, alt, title = '', width, height, className = ''} : ComponentProps) {
  return (
    <img
      src={src}
      alt={alt}
      title={title}
      width={width}
      height={height}
      className={className}
    />
  );
};
