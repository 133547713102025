import { Outlet } from 'react-router-dom';
import { Navbar } from '../components';

export default function Layout() {
  /* Render */
  return (
    <div className="container max-w-screen-md mx-auto px-4">
      <Navbar />
      <Outlet />
    </div>
  );
};
