import { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate, useSearchParams, useLocation, useParams } from 'react-router-dom';
import { PageTitle, ReceiptDetails, LightboxReceiptPrint } from '../components';
import { GetNewReceipt, SaveNewReceipt, PrintNewReceipt } from '../graphql';
import { useGraphQLQuery, useGraphQLMutation } from '../hooks';
import { ReceiptLoading } from '../interfaces';
import { AuthContext } from '../utils/context';
import { format_url } from '../utils/formatters';

export default function ReceiptNew() {
  const [actionType, setActionType] = useState('printed');
  const [actionList, setActionList] = useState<any>({
    save: () => DoMutationSaveNewReceipt(),
    print: () => ConfirmDoMutationPrintNewReceipt(),
    incorrect: () => FindAgain(0)
  });
  const [modal, setModal] = useState('');
  const [modalStatusCode, setModalStatusCode] = useState(0);
  const [receiptSaved, setReceiptSaved] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { deviceId, receiptId } = useParams();
  const [searchParams, ] = useSearchParams();
  const redirectParams = searchParams.get('params');
  const authenticated = useContext(AuthContext);

  /* Functions */
  const FindAgain = useCallback((amount: number) => {
    navigate(format_url(['/find', deviceId || ''], redirectParams === 'scan' ? undefined : { 
      params: amount
    }));
  }, [deviceId, redirectParams, navigate]);

  /* SaveNewReceipt mutation */
  const [MutationSaveNewReceiptFunction, { data: MutationSaveNewReceiptData }] = useGraphQLMutation(SaveNewReceipt);
  
  const DoMutationSaveNewReceipt = useCallback(() => {
    if (authenticated) {
      setActionType('saving');
      MutationSaveNewReceiptFunction({
        variables: { DeviceId: deviceId, ReceiptId: receiptId }
      });
    } else {
      navigate(format_url(['/login'], { 
        redirect: location.pathname,
        redirect_success_params: 'save'
      }));
    }
  }, [deviceId, receiptId, location, authenticated, MutationSaveNewReceiptFunction, navigate]);

  useEffect(() => {
    if (MutationSaveNewReceiptData && MutationSaveNewReceiptData.SaveNewReceipt && MutationSaveNewReceiptData.SaveNewReceipt.StatusCode) {
      switch(MutationSaveNewReceiptData.SaveNewReceipt.StatusCode) {
        case 200:
          break;
        case 400:
        case 403:
        case 404:
        case 409:
        case 500:
        default:
          break;
      }

      if (redirectParams) {
        navigate(format_url([location.pathname]), { replace: true });
      }

      setActionType('saved');
      setActionList({
        list: () => navigate(format_url(['/receipts']))
      });
    }
  }, [redirectParams, location, MutationSaveNewReceiptData, navigate]);
  
  /* PrintNewReceipt mutation */
  const [MutationPrintNewReceiptFunction, { data: MutationPrintNewReceiptData }] = useGraphQLMutation(PrintNewReceipt);
  
  const DoMutationPrintNewReceipt = () => {
    setModalStatusCode(102);
    setActionType('printed');
    MutationPrintNewReceiptFunction({
      variables: { DeviceId: deviceId, ReceiptId: receiptId }
    });
  };
  
  const ConfirmDoMutationPrintNewReceipt = () => {
    setModal('print');
    setModalStatusCode(0);
  };

  useEffect(() => {
    if (MutationPrintNewReceiptData && MutationPrintNewReceiptData.PrintNewReceipt && MutationPrintNewReceiptData.PrintNewReceipt.StatusCode) {
      setModalStatusCode(MutationPrintNewReceiptData.PrintNewReceipt.StatusCode);
    }
  }, [MutationPrintNewReceiptData]);

  /* GetNewReceipt query */
  const {
    loading: QueryGetNewReceiptLoading,  // eslint-disable-line @typescript-eslint/no-unused-vars
    data: QueryGetNewReceiptData
  } = useGraphQLQuery(GetNewReceipt, { variables: { DeviceId: deviceId, ReceiptId: receiptId }});

  useEffect(() => {
    if (QueryGetNewReceiptData && QueryGetNewReceiptData.GetNewReceipt) {
      if (!QueryGetNewReceiptData.GetNewReceipt.ReceiptId) {
        navigate(format_url(['/find', deviceId || '']), { replace: true });
      } else if (authenticated && redirectParams === 'save' && !receiptSaved) {
        setReceiptSaved(true);
        DoMutationSaveNewReceipt();
      } else if (!receiptSaved) {
        if (QueryGetNewReceiptData.GetNewReceipt.Meta) {
          try {
            const meta = JSON.parse(QueryGetNewReceiptData.GetNewReceipt.Meta);
            if (meta.AllowPrint && meta.AllowPrint === 'Allow') {
              setActionType('new');
            }
          } catch(e) {}
        }

        if (QueryGetNewReceiptData.GetNewReceipt.Total) {
          setActionList((actionList: any) => {
            return { ...actionList, incorrect: () => FindAgain(QueryGetNewReceiptData.GetNewReceipt.Total) }
          });
        }
      }
    }
  }, [deviceId, QueryGetNewReceiptData, authenticated, redirectParams, receiptSaved, DoMutationSaveNewReceipt, FindAgain, navigate]);

  /* Render */
  return (<>
    <PageTitle title="YOUR RECEIPT" />

    {modal === 'print' ? (
      <LightboxReceiptPrint statusCode={modalStatusCode} receipt={QueryGetNewReceiptData.GetNewReceipt} print={DoMutationPrintNewReceipt} close={() => setModal('')} />
    ) : (null)}
    
    {QueryGetNewReceiptData && QueryGetNewReceiptData.GetNewReceipt && QueryGetNewReceiptData.GetNewReceipt.ReceiptId ? (
      <ReceiptDetails receipt={QueryGetNewReceiptData.GetNewReceipt} actionType={actionType} actionList={actionList} />
    ) : (
      <ReceiptDetails receipt={ReceiptLoading} actionType={''} actionList={{}} />
    )}
  </>);
};

