import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Image } from '.';
import { AuthContext } from '../utils/context';
import ImageReseedLogo from '../assets/img-reseed-logo.png';
import ImageIconProfile from '../assets/icon-profile.png';

export default function Navbar () {
  const authenticated = useContext(AuthContext);
  const [isBrowser,setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(typeof window !== 'undefined');
  }, [setIsBrowser]);

  return (<>
    <header>
      <nav className="flex items-center justify-between pt-4 pb-5">
        <div className="logo">
          <Link to={isBrowser && authenticated ? '/receipts' : '/'}>
            <Image
              src={ImageReseedLogo}
              alt="Reseed Logo"
              width={60}
              height={80}
              className="max-w-[40px] md:max-w-[60px]"
            />
          </Link>
        </div>
        
        {isBrowser && authenticated ? (
          <Link to='/profile'>
            <Image
            src={ImageIconProfile}
            alt="Reseed Logo"
            width={50}
            height={50}
            className="max-w-[40px] md:max-w-[50px]"
            />
          </Link>
        ):(
          null
        )}
      </nav>
    </header>
  </>);
};
