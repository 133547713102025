import { Image } from '..';
import ImageReseedLogo from '../../assets/img-reseed-logo-white.png';

export default function Logo() {
  return (
    <Image
      src={ImageReseedLogo}
      alt="Reseed Logo"
      width={50}
      height={66}
      title="Reseed"
      className='mx-auto'
    />
  );
};
