import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageTitle, Welcome, ReceiptsListing } from '../components';
import { ListReceipts } from '../graphql';
import { useGraphQLQuery } from '../hooks';
import { Chit } from '../interfaces';
import { format_url } from '../utils/formatters';

export default function Receipts() {
  const [chits, setChits] = useState([]);
  const [nextToken, setNextToken] = useState('');
  const navigate = useNavigate();

  /* ListReceipts query */
  const { loading: QueryListReceiptsLoading, data: QueryListReceiptsData, refetch: QueryListReceiptsRefetch } = useGraphQLQuery(ListReceipts);
  
  useEffect(() => {
    if (QueryListReceiptsData && QueryListReceiptsData.ListReceipts) {
      if (QueryListReceiptsData.ListReceipts.Receipts) {
        setChits((chits) => {
          const current_chits = JSON.parse(JSON.stringify(chits));
          const new_chits = QueryListReceiptsData.ListReceipts.Receipts.filter((nc:Chit) => {
            return current_chits.findIndex((cc:Chit) => cc.ReceiptId === nc.ReceiptId) < 0;
          });
          return current_chits.concat(new_chits);
        });
      }
      if (QueryListReceiptsData.ListReceipts.Pagination && QueryListReceiptsData.ListReceipts.Pagination.NextToken && QueryListReceiptsData.ListReceipts.Pagination.NextToken !== '') {
        setNextToken(QueryListReceiptsData.ListReceipts.Pagination.NextToken);
      }
    }
  }, [QueryListReceiptsData]);

  const DoQueryListReceiptsRefetch = () => {
    QueryListReceiptsRefetch({ 
      Filters: JSON.stringify({}),
	    SortBy: '',
	    NextToken: nextToken
    });
  };

  /* Functions */
  const GoToProfile = () => {
    navigate(format_url(['/profile']));
  }

  /* Render */
  return (<>
    <PageTitle title="YOUR RECEIPTS" />

    {QueryListReceiptsLoading ? (
      <div className="mt-16 text-center">
        <span className="cus-loader"></span>
      </div>
    ) : (
      chits.length > 0 ? (
        <ReceiptsListing receipts={chits} hasMore={nextToken !== ''} loadMore={DoQueryListReceiptsRefetch} />
      ) : (
        <Welcome profile={GoToProfile} />
      )
    )}
  </>);
};
