import { MOBILE_FORMATS } from './constants';

export function is_valid_mobile(number: string) {
  const country_codes = Object.keys(MOBILE_FORMATS);
  for (let i = 0; i < country_codes.length; i++) {
    const country_mobile_config = MOBILE_FORMATS[country_codes[i] as keyof typeof MOBILE_FORMATS];
    const idd_prefix = ['+', country_mobile_config['idd_code']].join('');
    if (number.indexOf(idd_prefix) === 0) {
      const mobile = number.substring(idd_prefix.length);
      const re = new RegExp(country_mobile_config['format'], 'g');
      if (re.test(mobile)) {
        return true;
      }
      break;
    }
  }
  
  return false;
};
