import { PhoneInput, defaultCountries, parseCountry, } from 'react-international-phone';
import 'react-international-phone/style.css';
import { LightboxActionContainerBlock, Button, VerificationText } from '..';
import type { Receipt } from '../../interfaces';
import { DEFAULT_COUNTRY, MOBILE_FORMATS } from '../../utils/constants';

type ComponentProps = {
  statusCode: number,
  receipt: Receipt,
  phoneRequired: boolean,
  phoneNumber: string,
  setPhoneNumber: any,
  request: () => void,
  close: () => void,
  done: () => void
};

export default function LightboxReceiptRequest({ statusCode, receipt, phoneRequired, phoneNumber, setPhoneNumber, request, close, done }: ComponentProps) {
  const getText = (textType: string, statusCode: number) => {
    if (textType === 'header') {
      switch(statusCode) {
        case 200: return 'Request submitted';
        case 404: return 'Request error';
        case 500: return 'Request error';
        default: return 'Request for receipt';
      }
    } else if (textType === 'instructions') {
      switch(statusCode) {
        case 102: return 'Submitting, please wait.';
        case 200: return 'We\'ve received your request for the receipt.';
        case 404: return 'Please scan the QR code again.';
        case 500: return 'An error occurred while submitting your request. Please try again.';
        default: return 'Provide us your mobile number and we\'ll get back to you soonest.';
      }
    }

    return '';
  };

  const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return Object.keys(MOBILE_FORMATS).map(c => c.toLocaleLowerCase()).includes(iso2);
  });

  const mobile_block = (<>
    <VerificationText text="Your mobile number" />
    <div className="text-center mx-12">
      <PhoneInput
        defaultCountry={DEFAULT_COUNTRY.toLocaleLowerCase()}
        forceDialCode={true}
        countries={countries}
        value={phoneNumber}
        onChange={setPhoneNumber}
      />
    </div>
    {[400].indexOf(statusCode) >= 0 ? <p className="text-xs md:text-lg text-error mt-4">Please provide a valid mobile number.</p> : null}
  </>);

  return (
    <LightboxActionContainerBlock 
      header={getText('header', statusCode)}
      instructions={getText('instructions', statusCode)} 
      loading={[102].indexOf(statusCode) >= 0 ? true : false}
      inputBlock={phoneRequired && [0, 400].indexOf(statusCode) >= 0 ? mobile_block : undefined}
      receipt={receipt} close={close} >
      
      {[0, 400].indexOf(statusCode) >= 0 ? (
        <div className="flex">
          <div className="w-6/12 pe-2">
            <Button className="bg-purple" text="Submit" onClick={request} />
          </div>
          <div className="w-6/12 ps-2">
            <Button className="bg-[#a6a6a6]" text="Cancel" onClick={close} />
          </div>
        </div>
      ) : (null)}

      {[200].indexOf(statusCode) >= 0 ? (
        <Button className="bg-purple" text="Okay" onClick={done} />
      ) : (null)}

      {[0, 102, 200, 400].indexOf(statusCode) < 0 ? (
        <Button className="bg-purple" text="Okay" onClick={close} />
      ) : (null)}

    </LightboxActionContainerBlock>
  );
};
