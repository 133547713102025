import { Image } from '..';
import styles from './styles.module.css'
import ImageIconArrow from '../../assets/icon-arrow.png';

type ComponentProps = {
  text : string,
  variant: string,
  loading?: boolean,
  loadingClasses?: string
};

export default function BodyText({ text, variant, loading = false, loadingClasses = '' } : ComponentProps) {
  const getClassNames = ({ text, variant, loading, loadingClasses }: ComponentProps) => {
    const cssClasses = [];
    switch(variant) {
      case 'size-lg': cssClasses.push('text-sm', 'md:text-lg', 'mb-2'); break;
      case 'copy': cssClasses.push('text-md', 'mb-2'); break;
      case 'outlet-name': cssClasses.push('text-[12px]', 'md:text-lg', 'leading-none'); break;
      case 'outlet-location': cssClasses.push('text-[#a6a6a6]', 'text-[10px]', 'md:text-sm', 'leading-none', 'mt-1', 'md:mt-0'); break;
      case 'receipt-outlet-name': cssClasses.push('text-md', 'md:text-2xl', 'font-semibold', 'text-cyan', 'mb-1'); break;
      case 'receipt-outlet-name-light': cssClasses.push('text-md', 'md:text-2xl', 'font-semibold', 'text-[#a6a6a6]', 'mb-1'); break;
      case 'receipt-text': cssClasses.push('text-[12px]', 'md:text-lg'); break;
      case 'receipt-text-light': cssClasses.push('text-[12px]', 'text-[#a6a6a6]', 'md:text-lg'); break;
      case 'receipt-item-quantity': cssClasses.push('text-[12px]', 'md:text-lg', 'me-3', 'md:me-5'); break;
    }

    if (loading) {
      cssClasses.push(styles.textLoading);
      switch(variant) {
        case 'size-lg': cssClasses.push('h-4'); break;
        case 'copy': cssClasses.push('h-4'); break;
        case 'outlet-name': cssClasses.push('h-4'); break;
        case 'outlet-location': cssClasses.push('h-4'); break;
        case 'receipt-outlet-name': cssClasses.push('h-5'); break;
        case 'receipt-text': cssClasses.push('h-4'); break;
        case 'receipt-item-quantity': cssClasses.push('h-4'); break;
      }

      const additionalClasses = loadingClasses?.split(' ');
      if (additionalClasses && additionalClasses.length) {
        cssClasses.push(...additionalClasses);
      }
    }

    return cssClasses.join(' ');
  };

  const getText = ({ text, variant, loading, loadingClasses }: ComponentProps) => {
    return loading ? '' : text;
  };

  return (<>
    { variant === "outlet-name" ? (
      <p className={getClassNames({ text, variant, loading, loadingClasses })}>
        {text}
        <Image
          src={ImageIconArrow}
          alt="Arrow Icon"
          width={6}
          height={9}
          className="inline-block ms-3"
        />
      </p>
    ) : (
      <p className={getClassNames({ text, variant, loading, loadingClasses })}>{getText({ text, variant, loading, loadingClasses })}</p>
    )}
  </>);
};