import { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams, useLocation, useParams } from 'react-router-dom';
import { PageTitle, ReceiptFind, ChitBlock, LightboxReceiptRequest } from '../components';
import { FindNewReceipts, RequestNewReceipt } from '../graphql';
import { useGraphQLLazyQuery, useGraphQLMutation } from '../hooks';
import { Chit, ReceiptLoading } from '../interfaces';
import { AuthContext } from '../utils/context';
import { format_url } from '../utils/formatters';

export default function Find() {
  const navigate = useNavigate();
  const location = useLocation();
  const { deviceId } = useParams();
  const [searchParams, ] = useSearchParams();
  const amountParams = searchParams.get('params');
  
  const [receipt, setReceipt] = useState(ReceiptLoading);
  const [amount, setAmount] = useState(amountParams && !isNaN(parseFloat(amountParams)) ? parseFloat(amountParams) : 0);
  const [findStatus, setFindStatus] = useState(0);
  const [modal, setModal] = useState('');
  const [modalStatusCode, setModalStatusCode] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showCannotFind, setShowCannotFind] = useState(amountParams && !isNaN(parseFloat(amountParams)) ? true : false);
  const authenticated = useContext(AuthContext);

  /* FindNewReceipts query */
  const [QueryFindNewReceiptsFunction, { loading: QueryFindNewReceiptsLoading, data: QueryFindNewReceiptsData }] = useGraphQLLazyQuery(FindNewReceipts);

  useEffect(() => {
    if (amountParams) {
      navigate(format_url([location.pathname]), { replace: true });
      setShowCannotFind(true);
    }

    if (!QueryFindNewReceiptsLoading) {
      QueryFindNewReceiptsFunction({ variables: { 
        DeviceId: deviceId,
        Amount: amount
      }});
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    if (QueryFindNewReceiptsData && QueryFindNewReceiptsData.FindNewReceipts) {
      if (QueryFindNewReceiptsData.FindNewReceipts.DeviceId) {
        setReceipt((receipt) => {
          const current_receipt = JSON.parse(JSON.stringify(receipt));
          current_receipt.Category = QueryFindNewReceiptsData.FindNewReceipts.Category;
          current_receipt.StoreName = QueryFindNewReceiptsData.FindNewReceipts.StoreName;
          current_receipt.LocationName = QueryFindNewReceiptsData.FindNewReceipts.LocationName;
          current_receipt.Address = QueryFindNewReceiptsData.FindNewReceipts.Address;
          current_receipt.Logo = QueryFindNewReceiptsData.FindNewReceipts.Logo;
          current_receipt.GoogleMap.Embed = QueryFindNewReceiptsData.FindNewReceipts.GoogleMap.Embed;
          current_receipt.GoogleMap.Link = QueryFindNewReceiptsData.FindNewReceipts.GoogleMap.Link;
          current_receipt.Currency = QueryFindNewReceiptsData.FindNewReceipts.Currency;
          return current_receipt;
        });
        
        if (QueryFindNewReceiptsData.FindNewReceipts.DeviceId !== deviceId) {
          navigate(format_url(['/find', QueryFindNewReceiptsData.FindNewReceipts.DeviceId]), { replace: true });
        }

        if (findStatus === 102) {
          setShowCannotFind(true);
        }
        
        if (!QueryFindNewReceiptsLoading && QueryFindNewReceiptsData.FindNewReceipts.Receipts.length === 0) {
          setFindStatus((findStatus) => {
            return findStatus === 102 ? 404 : findStatus;
          });
        } else if (QueryFindNewReceiptsData.FindNewReceipts.Receipts.length === 1 && !showCannotFind) {
          navigate(format_url(['/find', deviceId, QueryFindNewReceiptsData.FindNewReceipts.Receipts[0].ReceiptId]));
        } else if (QueryFindNewReceiptsData.FindNewReceipts.Receipts.length >= 1) {
          setFindStatus(0);
        }        
      } else {
        navigate(format_url(['/find']), { replace: true });
      }
    }
  }, [deviceId, findStatus, showCannotFind, QueryFindNewReceiptsLoading, QueryFindNewReceiptsData, navigate]);

  const DoQueryFindNewReceiptsRefetch = () => {
    if (amount > 0) {
      setFindStatus(102);
      QueryFindNewReceiptsFunction({ variables: { 
        DeviceId: deviceId,
        Amount: amount
      }});
    } else {
      setFindStatus(400);
    }
  };

  /* RequestNewReceipt mutation */
  const [MutationRequestNewReceiptFunction, { data: MutationRequestNewReceiptData }] = useGraphQLMutation(RequestNewReceipt);
  
  const DoMutationRequestNewReceipt = () => {
    setModalStatusCode(102);
    if (authenticated) {
      MutationRequestNewReceiptFunction({
        variables: { DeviceId: deviceId, Amount: amount }
      });
    } else {
      const regex_sg = /^\+65[8-9][0-9]{7}$/;
      const regex_vn = /^\+84[356789][0-9]{8}$/;

      if (regex_sg.test(phoneNumber) || regex_vn.test(phoneNumber)) {
        MutationRequestNewReceiptFunction({
          variables: { DeviceId: deviceId, Amount: amount, Mobile: phoneNumber }
        });
      } else {
        setModalStatusCode(400);
      }      
    }
  };
  
  const ConfirmDoMutationRequestNewReceipt = () => {
    setModal('request');
    setModalStatusCode(0);
  };

  useEffect(() => {
    if (MutationRequestNewReceiptData && MutationRequestNewReceiptData.RequestNewReceipt && MutationRequestNewReceiptData.RequestNewReceipt.StatusCode) {
      setModalStatusCode(MutationRequestNewReceiptData.RequestNewReceipt.StatusCode);
    }
  }, [MutationRequestNewReceiptData]);

  /* Render */
  return (<>
    <PageTitle title="FIND RECEIPT" />

    {modal === 'request' ? (
      <LightboxReceiptRequest statusCode={modalStatusCode} receipt={{...receipt, Total: amount}} 
        phoneRequired={!authenticated} phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} 
        request={DoMutationRequestNewReceipt} close={() => setModal('')} 
        done={() => navigate(format_url(['/']))} />
    ) : (null)}

    <ReceiptFind receipt={receipt} amount={amount} setAmount={setAmount} find={DoQueryFindNewReceiptsRefetch} findStatus={findStatus} setFindStatus={setFindStatus} />

    {QueryFindNewReceiptsData && QueryFindNewReceiptsData.FindNewReceipts && QueryFindNewReceiptsData.FindNewReceipts.Receipts && QueryFindNewReceiptsData.FindNewReceipts.Receipts.length > 0 ? (
      <div className="mt-9">
        {QueryFindNewReceiptsData.FindNewReceipts.Receipts.map((chit: Chit, idx: number) =>
          <ChitBlock key={idx} chit={chit} url={['', 'find', deviceId || '']} />
        )}
      </div>
    ) : (
      null
    )}

    {receipt.StoreName && amount > 0 && showCannotFind ? (
      <button className="text-purple underline text-[12px] md:text-lg w-full mt-4" onClick={ConfirmDoMutationRequestNewReceipt}>Still cannot find?</button>
    ) : (
      null
    )}
  </>);
};
