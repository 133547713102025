import { gql } from '@apollo/client';

const query = gql`
  query query {
    GetProfile {
      UserId
      Mobile
      Name
      Joined
      Gender
      AgeRange
      BirthMonth
      Email
      OptIn
    }
  }
`;

export default query;
