import { PhoneInput, defaultCountries, parseCountry, } from 'react-international-phone';
import 'react-international-phone/style.css';
import { Button, Logo, VerificationText } from '.';
import { DEFAULT_COUNTRY, MOBILE_FORMATS } from '../utils/constants';

type ComponentProps = {
  phoneNumber: string,
  setPhoneNumber: any,
  errorCode: number,
  requestOtp: () => void,
};

export default function LoginPhone({ phoneNumber, setPhoneNumber, errorCode, requestOtp }: ComponentProps) {
  const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return Object.keys(MOBILE_FORMATS).map(c => c.toLocaleLowerCase()).includes(iso2);
  });

  const TermsAndConditions = <a href="https://reseed.co/terms-of-use.html" target="_blank" className="text-cyan underline">Terms&nbsp;and&nbsp;Conditions</a> // eslint-disable-line react/jsx-no-target-blank
  const PrivacyPolicy = <a href="https://reseed.co/privacy-policy-addendum.html" target="_blank" className="text-cyan underline">Privacy&nbsp;Policy</a> // eslint-disable-line react/jsx-no-target-blank

  return (<>
    <div className="min-h-[calc(100vh-240px)] flex items-center justify-center">
      <div className="text-center">
        <Logo />
        <VerificationText text="Mobile number verification" />
        <div className="text-center mx-4">
          <PhoneInput
            defaultCountry={DEFAULT_COUNTRY.toLocaleLowerCase()}
            forceDialCode={true}
            countries={countries}
            value={phoneNumber}
            onChange={setPhoneNumber}
          />
          {[400].indexOf(errorCode) >= 0 ? <p className="text-xs md:text-lg text-error mt-4">Please provide a valid mobile number.</p> : null}
          {[0, 102, 200, 400].indexOf(errorCode) < 0 ? <p className="text-xs md:text-lg text-error mt-4">An error has occurred please try again later.</p> : null}
        </div>        
        <p className="text-xs md:text-lg text-white mt-4 max-w-[448px] mx-auto">Your account with Reseed will be linked to your mobile number. For your security, we will verify your account with an SMS OTP in the next step.</p>
        <div className="mt-4">
          <span className={[102].indexOf(errorCode) >= 0 ? 'cus-loader' : 'cus-loader-holder'}></span>
        </div>
      </div>      
    </div>
    <div className="bottom-div absolute left-0 bottom-[95px] md:bottom-6 w-full">
      <div className="max-w-screen-md mx-auto px-4">
        <Button className="bg-purple mb-4" text="Verify" onClick={requestOtp} />
        <p className="text-xs md:text-lg text-white">
          By tapping on the VERIFY button, you hereby agree to our {TermsAndConditions}, {PrivacyPolicy} and consent to receiving marketing and promotion from Reseed via SMS, push notification and messaging platforms like WhatsApp unless otherwise indicated in your profile. 
        </p>
      </div>
    </div>
  </>);
};
