
type ComponentProps = {
  title : string;
};

export default function PageTitle ({ title }: ComponentProps ) {
  return (
    <h1 className="text-cyan text-2xl md:text-4xl font-semibold uppercase mb-2.5">{title}</h1>
  );
};
