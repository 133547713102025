import { OutletInfoBlock } from '.';
import type { Receipt } from '../interfaces';

type ComponentProps = {
  receipt: Receipt,
  amount: number,
  setAmount: any,
  find: () => void,
  findStatus: number,
  setFindStatus: any,
};

export default function ReceiptFind({ receipt, amount, setAmount, find, findStatus, setFindStatus }: ComponentProps) {
  const store_loading = receipt.StoreName === '';

  const updateAmount = (e: React.FormEvent<HTMLInputElement|HTMLSelectElement>) => {
    const newAmount = e.currentTarget.value === '' || isNaN(parseFloat(e.currentTarget.value)) ? 0 : parseFloat(e.currentTarget.value);
    setAmount(newAmount);
    setFindStatus(0);
  };

  return (
    <main className="pt-6">
      <OutletInfoBlock name={receipt.StoreName} location={receipt.LocationName} logo={receipt.Logo} loading={store_loading} />
      {store_loading ? (
        null
      ) : (
        <div className="pt-5">
          <p className="md:text-2xl">To retrieve your receipt, enter the total numeric amount below.</p>
          <div className="input-group flex items-end mt-9">
            <label htmlFor="profileName" className="w-[100px] md:w-[150px] ps-1 border-b border-solid border-[#d1d1d1] pb-1 md:text-2xl">Amount <span className="ms-3">S$</span></label>
            <input className="border-b border-solid border-[#d1d1d1] w-[calc(100%-100px)] md:w-[calc(100%-150px)] pb-1 md:text-2xl" type="text" name="amount" defaultValue={amount > 0 ? amount : ''} onChange={updateAmount} />
          </div>
          {[102].indexOf(findStatus) >= 0 ? <p className="text-cyan text-[12px] md:text-lg w-full mt-4">Please hang on</p> : null}
          {[400].indexOf(findStatus) >= 0 ? <p className="text-error text-[12px] md:text-lg w-full mt-4">Invalid amount</p> : null}
          {[404].indexOf(findStatus) >= 0 ? <p className="text-error text-[12px] md:text-lg w-full mt-4">No receipt with this amount found</p> : null}
          <button type="button" className="text-[13px] md:text-lg leading-none uppercase font-semibold bg-purple hover:bg-cyan transition-all duration-300 hover:duration-300 hover:transition-all block text-white rounded-lg md:py-1.5 py-2.5 px-4 mt-9 w-full" onClick={find}>Find Receipt</button>
        </div>
      )}
    </main>
  );
};
