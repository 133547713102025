import { BodyText, Button, Image } from '.';
import ImageHomepage from '../assets/img-homepage.png';

type ComponentProps = {
  login?: () => void,
  profile?: () => void 
};

export default function Welcome({ login = undefined, profile = undefined } : ComponentProps) {
  const FindOutMore = <a href="https://reseed.co/" className="text-sm md:text-lg text-link" target="_blank" rel="noopener">Find out more</a> // eslint-disable-line react/jsx-no-target-blank

  return (
    <main className="welcome-container">
      <div className="text-center py-5 h-[calc(100vh-260px)] md:h-auto flex flex-col justify-center">
        <Image
          src={ImageHomepage}
          alt="Reseed Logo"
          width={254}
          height={262}
          className='mx-auto img-home'
        />
        <h2 className="mt-8 mb-2.5 text-cyan text-2xl md:text-4xl font-semibold">Welcome to <span className="text-fuchsia">Reseed</span>!</h2>
        <BodyText variant="size-lg" text="Scan the Reseed QR code to get your digital receipt." />
        {FindOutMore}
      </div>
      
      {login ? (
        <div className="btn-container text-center absolute bottom-8 left-0 w-full">
          <div className="max-w-screen-md mx-auto px-4">
            <BodyText variant="size-lg" text="Login to retrieve your saved receipts." />
            <Button className="bg-purple" onClick={login} text="Login" />
          </div>
        </div>
      ) : (null)}

      {profile ? (
        <div className="btn-container text-center">
          <BodyText variant="size-lg" text="Update your profile." />
          <Button className="bg-purple" onClick={profile} text="My profile" />
        </div>
      ) : (null)}
    </main>    
  );
};
