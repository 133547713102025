import { gql } from '@apollo/client';

const query = gql`
  query query {
    CheckIsAuthenticated {
      Status
      StatusCode
      Message
      Id
      Data
    }
  }
`;

export default query;
