import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageTitle, ReceiptDetails } from '../components';
import { GetLatestNewReceipt } from '../graphql';
import { useGraphQLQuery } from '../hooks';
import { ReceiptLoading } from '../interfaces';
import { SCAN_RECHECK_LIMIT, SCAN_RECHECK_INTERVAL } from '../utils/constants';
import { format_url } from '../utils/formatters';

export default function Scan() {
  const [recheckCounter, setRecheckCounter] = useState(0);
  const [receipt, setReceipt] = useState(ReceiptLoading);
  const [deviceId, setDeviceId] = useState('');
  const navigate = useNavigate();
  const { deviceId: deviceIdOrName } = useParams();

  /* GetReceipt query */
  const {
    loading: QueryGetLatestNewReceiptLoading, // eslint-disable-line @typescript-eslint/no-unused-vars
    data: QueryGetLatestNewReceiptData,
    refetch: QueryGetLatestNewReceiptRefetch
  } = useGraphQLQuery(GetLatestNewReceipt, { variables: { DeviceId: deviceIdOrName }});

  useEffect(() => {
    if (QueryGetLatestNewReceiptData && QueryGetLatestNewReceiptData.GetLatestNewReceipt) {
      if (QueryGetLatestNewReceiptData.GetLatestNewReceipt.DeviceId) {
        setDeviceId(QueryGetLatestNewReceiptData.GetLatestNewReceipt.DeviceId);
        setReceipt((receipt) => {
          const current_receipt = JSON.parse(JSON.stringify(receipt));
          current_receipt.Category = QueryGetLatestNewReceiptData.GetLatestNewReceipt.Category;
          current_receipt.StoreName = QueryGetLatestNewReceiptData.GetLatestNewReceipt.StoreName;
          current_receipt.LocationName = QueryGetLatestNewReceiptData.GetLatestNewReceipt.LocationName;
          current_receipt.Address = QueryGetLatestNewReceiptData.GetLatestNewReceipt.Address;
          current_receipt.Logo = QueryGetLatestNewReceiptData.GetLatestNewReceipt.Logo;
          current_receipt.GoogleMap.Embed = QueryGetLatestNewReceiptData.GetLatestNewReceipt.GoogleMap.Embed;
          current_receipt.GoogleMap.Link = QueryGetLatestNewReceiptData.GetLatestNewReceipt.GoogleMap.Link;
          current_receipt.Currency = QueryGetLatestNewReceiptData.GetLatestNewReceipt.Currency;
          return current_receipt;
        });

        if (QueryGetLatestNewReceiptData.GetLatestNewReceipt.Receipts.length >= 1) {
          navigate(format_url(['/find', QueryGetLatestNewReceiptData.GetLatestNewReceipt.DeviceId, QueryGetLatestNewReceiptData.GetLatestNewReceipt.Receipts[0].ReceiptId], {
            params: 'scan'
          }));
        }
      } else {
        navigate(format_url(['/scan']), { replace: true });
      }
    }    
  }, [deviceIdOrName, QueryGetLatestNewReceiptData, navigate]);

  /* Functions */
  useEffect(() => { 
    const recheck = setInterval(() => { 
      if (recheckCounter < SCAN_RECHECK_LIMIT) {
        if (deviceId !== '' && deviceId !== deviceIdOrName) {
          navigate(format_url(['/scan', deviceId]), { replace: true });
        } else {
          setRecheckCounter(recheckCounter + 1);
          QueryGetLatestNewReceiptRefetch();
        }
      } else {
        clearInterval(recheck);
        navigate(format_url(['/find', deviceId || deviceIdOrName || '']));
      }
    }, SCAN_RECHECK_INTERVAL); 

    return () => clearInterval(recheck); 
  }, [deviceIdOrName, deviceId, recheckCounter, QueryGetLatestNewReceiptRefetch, navigate]);

  /* Render */
  return (<>
    <PageTitle title="YOUR RECEIPT" />
    <ReceiptDetails receipt={receipt} actionType={''} actionList={{}} />
  </>);
};
