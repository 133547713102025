export const OTP_REQUEST_AGAIN_TIMEOUT = 60;
export const OTP_REQUEST_AGAIN_ALLOWED = 3;
export const SCAN_RECHECK_LIMIT = 5;
export const SCAN_RECHECK_INTERVAL = 4000;
export const DEFAULT_CURRENCY = 'SGD';
export const DEFAULT_COUNTRY = 'SG';

export const MOBILE_FORMATS = {
  'SG': {
    'idd_code': '65',
    'format': '^[8-9][0-9]{7}$',
    'display': {
      'parse': '^([0-9]{4})([0-9]{4})$',
      'format': '$1 $2'
    },
    'otp': {
      'timeout': 90,
      'format': '^[0-9]{6}$'
    }
  },
  'VN': {
    'idd_code': '84',
    'format': '^[356789][0-9]{8}$',
    'display': {
      'parse': '^([0-9]{2})([0-9]{3})([0-9]{2})([0-9]{2})$',
      'format': '$1 $2 $3 $4'
    },
    'otp': {
      'timeout': 90,
      'format': '^[0-9]{6}$'
    }
  },
  'MY': {
    'idd_code': '60',
    'format': '^(1[02-46-9][0-9]{7}|11[0-9]{8})$',
    'display': {
      'parse': '^([0-9]{2})([0-9]{3,4})([0-9]{4})$',
      'format': '$1 $2 $3 $4'
    },
    'otp': {
      'timeout': 90,
      'format': '^[0-9]{6}$'
    }
  }
};

export const CURRENCIES = {
  'USD': {
    'prefix': 'US$',
    'postfix': '',
    'thousands': 3,
    'thousands_separator': ',',
    'decimals': 2,
    'decimal_point': '.',
  },
  'SGD': {
    'prefix': 'S$',
    'postfix': '',
    'thousands': 3,
    'thousands_separator': ',',
    'decimals': 2,
    'decimal_point': '.',
  },
  'JPY': {
    'prefix': '¥',
    'postfix': '',
    'thousands': 3,
    'thousands_separator': ',',
    'decimals': 0,
    'decimal_point': '.',
  },
  'VND': {
    'prefix': '',
    'postfix': 'đ',
    'thousands': 3,
    'thousands_separator': ',',
    'decimals': 0,
    'decimal_point': '.',
  }
};
