import { LightboxActionContainerBlock, Button } from '..';
import type { Receipt } from '../../interfaces';

type ComponentProps = {
  statusCode: number,
  receipt: Receipt,
  print: () => void,
  close: () => void
};

export default function LightboxReceiptPrint({ statusCode, receipt, print, close }: ComponentProps) {
  const getText = (textType: string, statusCode: number) => {
    if (textType === 'header') {
      switch(statusCode) {
        case 200: return 'Receipt printed';
        case 404: return 'Printing error';
        case 408: return 'Printing error';
        case 500: return 'Printing error';
        case 503: return 'Printing error';          
        default: return 'Save the environment';
      }
    } else if (textType === 'instructions') {
      switch(statusCode) {
        case 102: return 'Printing, please wait.';
        case 200: return 'Please collect it from the cashier.';
        case 404: return 'No such receipt.';
        case 408: return 'This receipt can no longer be printed.';
        case 500: return 'Unable to print. Please check with the cashier.';
        case 503: return 'Unable to print. Please check with the cashier.';
        default: return 'You can keep a digital copy on your phone instead of printing.';
      }
    }

    return '';
  };

  return (
    <LightboxActionContainerBlock 
      header={getText('header', statusCode)}
      instructions={getText('instructions', statusCode)} 
      loading={[102].indexOf(statusCode) >= 0 ? true : false}
      receipt={receipt} close={close} >
      
      {[0].indexOf(statusCode) >= 0 ? (
        <div className="flex">
          <div className="w-6/12 pe-2">
            <Button className="bg-[#a6a6a6]" text="Print" onClick={print} />
          </div>
          <div className="w-6/12 ps-2">
            <Button className="bg-purple" text="Cancel" onClick={close} />
          </div>
        </div>
      ) : (null)}

      {[0, 102].indexOf(statusCode) < 0 ? (
        <Button className="bg-purple" text="Okay" onClick={close} />
      ) : (null)}
    </LightboxActionContainerBlock>
  );
};
