import { gql } from '@apollo/client';

const query = gql`
  query query($DeviceId: ID!) {
    GetLatestNewReceipt(DeviceId: $DeviceId) {
      DeviceId
      Category
      StoreName
      LocationName
      Address
	    Logo
      GoogleMap {
        Embed
        Link
      }
      Currency
      Receipts {
        ReceiptId
        Category
        StoreName
        LocationName
        Logo
        Currency
        Total
        Timestamp
      }
    }
  }
`;

export default query;
