import { Image } from '..';
import ImageIconPhone from '../../assets/icon-phone.png';

type ComponentProps = {
    text : string
};

export default function VerificationText({text}: ComponentProps) {
  return (
    <p className="md:text-2xl text-white flex justify-center items-center mb-2.5 mt-5 md:mt-8">
      <Image
        src={ImageIconPhone}
        alt="Phone Icon"
        width={20}
        height={20}
        className='me-1.5'
      />
      {text}
    </p>
  );
};
