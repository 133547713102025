import { ReactNode } from 'react';
import { Image } from '..';
import ImageBtnClose from '../../assets/img-btn-close.png';

type ComponentProps = {
  children: ReactNode,
  close: ()=>void
};

export default function Overlay({ children, close } : ComponentProps) {
  return (
    <section className="login bg-black fixed top-0 left-0 w-screen h-screen">
      <div className="header text-right p-5 md:pe-5">
        <button type="button" onClick={close}>
          <Image
            src={ImageBtnClose}
            alt="Close Icon"
            width={14}
            height={15}
            className='mx-auto'
          />
        </button>
      </div>
      <div className="max-w-screen-md mx-auto px-4">
        {children}
      </div>
    </section>
  );
};
