import { gql } from '@apollo/client';

const mutation = gql`
  mutation mutation($Profile: InputProfile!) {
    UpdateProfile(Profile: $Profile) {
      Status
      StatusCode
      Message
      Id
      Data
    }
  }
`;

export default mutation;
