import { useNavigate } from 'react-router-dom';
import type { ApolloError } from '@apollo/client/errors';
import { format_url } from '../utils/formatters';

export default function useGraphQLErrorHandler() {
  const navigate = useNavigate();
  
  const handler = (error: ApolloError) => {
    if (error.networkError && 'statusCode' in error.networkError && error.networkError.statusCode === 401) {
      console.log('useGraphQLErrorHandler: networkError - redirecting to logout');
      navigate(format_url(['/']));
    } else if (error.graphQLErrors && 'errorType' in error.graphQLErrors[0] && error.graphQLErrors[0].errorType === 'Unauthorized') {
      console.log('useGraphQLErrorHandler: graphQLErrors - redirecting to logout');
      navigate(format_url(['/']));
    } else {
      console.log('useGraphQLErrorHandler: unknown');
    }
  };
  
  return handler;
};
