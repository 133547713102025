import { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Overlay, LoginPhone, LoginOtp } from '../components';
import { RequestOtp, RequestToken } from '../graphql';
import { useGraphQLMutation } from '../hooks';
import { OTP_REQUEST_AGAIN_ALLOWED } from '../utils/constants';
import { format_url } from '../utils/formatters';
import { is_valid_mobile } from '../utils/validators';

export default function Login() {
  const [authStage, setAuthStage] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [otpLastRequest, setOtpLastRequest] = useState(0);
  const [errorCode, setErrorCode] = useState(0);

  const [searchParams, ] = useSearchParams();
  const redirectUrl = searchParams.get('redirect');
  const redirectSuccessParams = searchParams.get('redirect_success_params');
  const redirectFailParams = searchParams.get('redirect_fail_params')
  
  useEffect(() => {
    localStorage.setItem('AccessToken', '');
  }, );

  /* Functions */
  const redirect = useCallback((success: boolean, defaultRedirect: string) => {
    if (redirectUrl) {
      const redirectParam = success ? (redirectSuccessParams ? redirectSuccessParams : null) : (redirectFailParams ? redirectFailParams : null);
      if (redirectParam) {
        goto(format_url([redirectUrl], { params: redirectParam}));
      } else {
        goto(format_url([redirectUrl]));
      }
    } else {
      goto(format_url([defaultRedirect]));
    }
  }, [redirectUrl, redirectSuccessParams, redirectFailParams]);

  const goto = (url: string) => {
    //navigate(url, { replace: true });
    window.location.replace(url);
  };

  const closeLogin = useCallback(() => {
    redirect(false, '/');
  }, [redirect]);

  /* RequestOtp mutation */
  const [MutationRequestOtpFunction, { data: MutationRequestOtpData }] = useGraphQLMutation(RequestOtp);
  
  const DoMutationRequestOtp = () => {
    setErrorCode(102);
    setOtp('');
    if (is_valid_mobile(phoneNumber)) {
      MutationRequestOtpFunction({
        variables: { Mobile: phoneNumber }
      });
    } else {
      setErrorCode(400);
    }
  };

  useEffect(() => {
    if (MutationRequestOtpData && MutationRequestOtpData.RequestOtp && MutationRequestOtpData.RequestOtp.StatusCode) {
      switch(MutationRequestOtpData.RequestOtp.StatusCode) {
        case 200:
          if (process.env.REACT_APP_OTP_DISPLAY_IN_CONSOLE) {
            console.log(MutationRequestOtpData.RequestOtp.Message);
          }
          if (process.env.REACT_APP_OTP_AUTOFILL) {
            const [,smsOtp] = MutationRequestOtpData.RequestOtp.Message.split(':');
            setOtp(smsOtp);
          }
          setOtpLastRequest(Date.now());
          setErrorCode(0);
          setAuthStage(1);
          break;
        case 400:
          setErrorCode(400);
          break;
        case 429:
          setAuthStage(1);
          setErrorCode(429);
          break;
        case 503:
        default:
          setErrorCode(503);
          break;
      }
    }
  }, [MutationRequestOtpData]);

  /* RequestToken mutation */
  const [MutationRequestTokenFunction, { data: MutationRequestTokenData }] = useGraphQLMutation(RequestToken);
  
  const DoMutationRequestToken = () => {
    const regex = /^[0-9]{6}$/;

    setErrorCode(102);
    if (regex.test(otp)) {
      setOtpLastRequest((t) => t + (OTP_REQUEST_AGAIN_ALLOWED * 1000));
      MutationRequestTokenFunction({
        variables: { Mobile: phoneNumber, Otp: otp }
      });
    } else {
      setErrorCode(400);
    }
  };

  useEffect(() => {
    if (MutationRequestTokenData && MutationRequestTokenData.RequestToken && MutationRequestTokenData.RequestToken.StatusCode) {
      switch(MutationRequestTokenData.RequestToken.StatusCode) {
        case 200:
          if (MutationRequestTokenData.RequestToken.Data) {
            try {
              const data = JSON.parse(MutationRequestTokenData.RequestToken.Data);
              if (data && data.AccessToken) {
                localStorage.setItem('AccessToken', data.AccessToken);
                redirect(true, '/receipts');
              } else {
                setAuthStage(0);
                setErrorCode(503);
              }
            } catch(e) {
              setAuthStage(0);
              setErrorCode(503);
            }
          } else {
            setAuthStage(0);
            setErrorCode(503);
          }
          break;
        case 400:
          setErrorCode(400);
          break;
        case 401:
          setErrorCode(401);
          break;
        case 503:
        default:
          setErrorCode(503);
          break;
      }
    }
  }, [redirect, MutationRequestTokenData]);

  /* Render */
  return (
    <Overlay close={closeLogin}>
      {authStage === 0 ? (
        <LoginPhone phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} errorCode={errorCode} requestOtp={DoMutationRequestOtp} />
      ) : (
        <LoginOtp phoneNumber={phoneNumber} otp={otp} setOtp={setOtp} errorCode={errorCode} otpLastRequest={otpLastRequest} requestToken={DoMutationRequestToken} requestOtp={DoMutationRequestOtp} />
      )}
    </Overlay>
  );
};

