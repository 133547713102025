import { ReactNode } from 'react';
import { ModalConfirmation, OutletInfoBlock, BodyText } from '..';
import type { Receipt } from '../../interfaces';
import { format_currency } from '../../utils/formatters';

type ComponentProps = {
  header: string,
  instructions: string,
  receipt: Receipt,
  loading: boolean,
  close: () => void,
  children: ReactNode,
  inputBlock?: ReactNode,
};

export default function LightboxActionContainerBlock({ header, instructions, receipt, loading, close, children, inputBlock = undefined }: ComponentProps) {
  return (
    <ModalConfirmation close={close}>
      <div className="text-center">
        <p className="text-md md:text-2xl text-cyan mt-7 font-semibold">{header}</p>
        <p className="text-[12px] md:text-lg text-white mt-2">{instructions}</p>
      </div>
      <div className="border border-solid border-gray pt-4 pb-3 px-4 mt-6">
        <OutletInfoBlock name={receipt.StoreName} location={receipt.LocationName} logo={receipt.Logo} loading={false} theme={'dark'} />
        <hr/>
        <div className="pt-2.5 pb-4">
          <div className="flex justify-between items-center mt-1.5">
            <BodyText text="Total" variant="receipt-text-light" loading={false} />
            <BodyText text={format_currency(receipt.Total, receipt.Currency)} variant="receipt-text-light" loading={false} loadingClasses={'w-12'} />
          </div>
        </div>
      </div>
      <div className="text-center mt-12">
        <span className={loading ? 'cus-loader' : 'cus-loader-holder'}></span>
      </div>
      
      {inputBlock ? (
        <div className="text-center">
          {inputBlock}
        </div> 
      ): (null)}

      <div className="container max-w-screen-md absolute left-0 right-0 bottom-[100px] md:bottom-5 mx-auto">
        {children}
      </div>
    </ModalConfirmation>
  );
};
