import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { Layout, Home, Login, Logout, Receipts, Receipt, Profile, Scan, ScanError, Find, FindError, ReceiptNew } from './pages';
import { useGraphQLClient } from './hooks';
import { AuthContext } from './utils/context';
import { jwtDecode } from 'jwt-decode';
import './App.css';

interface JwtPayload {
  client_id: string,
  exp: number
};

function App() {
  const accessToken = localStorage.getItem('AccessToken') || '';
  let authenticated = false;
  if (accessToken !== '') {
    try {
      const accessTokenDecoded = jwtDecode(accessToken) as JwtPayload;
      if (accessTokenDecoded.client_id && accessTokenDecoded.client_id === process.env.REACT_APP_COGNITO_CLIENT_ID) {
        if (accessTokenDecoded.exp) {
          const accessTokenExpiry = new Date(accessTokenDecoded.exp * 1000);
          if (accessTokenExpiry.getTime() <= Date.now()) {
            localStorage.setItem('AccessToken', '');
          } else {
            authenticated = true;
          }
        }
      }
    } catch(e) { console.log(e); }
  }
  const client = useGraphQLClient(process.env.REACT_APP_GRAPHQL_API_URL!, authenticated ? 'token' : 'api-key', authenticated ? accessToken : process.env.REACT_APP_GRAPHQL_API_KEY!);
  
  return (
    <ApolloProvider client={client}>
      <AuthContext.Provider value={authenticated}>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Layout />}>
              { authenticated ? (<>
                <Route index element={<Home />} />
                <Route path='receipts' element={<Receipts />} />
                <Route path='receipts/:receiptId' element={<Receipt />} />
                <Route path='profile' element={<Profile />} />
                <Route path='logout' element={<Logout />} />
                <Route path='*' element={<Navigate to='/' />} />
              </>) : (<>
              </>)
              }
              <Route index element={<Home />} />
              <Route path='*' element={<Navigate to='/' />} />
              <Route path='login' element={<Login />} />
              <Route path='scan' element={<ScanError />} />
              <Route path='scan/:deviceId' element={<Scan />} />
              <Route path='s/:deviceId' element={<Scan />} />
              <Route path='find' element={<FindError />} />
              <Route path='find/:deviceId' element={<Find />} />
              <Route path='find/:deviceId/:receiptId' element={<ReceiptNew />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthContext.Provider>
    </ApolloProvider>
  );
}

export default App;
