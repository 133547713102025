import { gql } from '@apollo/client';

const mutation = gql`
  mutation mutation($Mobile: String!) {
    RequestOtp(Mobile: $Mobile) {
      Status
      StatusCode
      Message
      Id
      Data
    }
  }
`;

export default mutation;
