import { BodyText, Image } from '..';
import type { ReceiptPayment, ReceiptTabulation } from '../../interfaces';
import { format_currency } from '../../utils/formatters';
import ImageIconPaymentAmex from '../../assets/img-payment-amex.png';
import ImageIconPaymentCash from '../../assets/img-payment-cash.png';
import ImageIconPaymentDiners from '../../assets/img-payment-diners.png';
import ImageIconPaymentFavePay from '../../assets/img-payment-favepay.png';
import ImageIconPaymentGrabPay from '../../assets/img-payment-grabpay.png';
import ImageIconPaymentMasterCard from '../../assets/img-payment-mastercard.png';
import ImageIconPaymentNets from '../../assets/img-payment-nets.png';
import ImageIconPaymentPayLah from '../../assets/img-payment-paylah.png';
import ImageIconPaymentVisa from '../../assets/img-payment-visa.png';

type ComponentProps = {
  currency: string,
  tabulation: ReceiptTabulation[],
  payment: ReceiptPayment,
  loading: boolean,
  simple?: boolean,
  theme?: string
};

export default function ReceiptTabulationBlock({ currency, tabulation, payment, loading, simple = false, theme = 'light' }: ComponentProps) {
  if (loading) {
    currency = 'SGD';
    tabulation = [
      { Description: 'Total', Amount: 0 },
    ];
  }

  if (simple) {
    tabulation = tabulation.slice(-1);
  }

  const DisplayPaymentIcon = (paymentMethod: string, displayType: string) => {
    const PaymentMethods = {
      'cash': { icon: ImageIconPaymentCash, description: 'Cash' },
      'nets': { icon: ImageIconPaymentNets, description: 'NETS' },
      
      'visa': { icon: ImageIconPaymentVisa, description: 'Visa' },
      'master': { icon: ImageIconPaymentMasterCard, description: 'MasterCard' },
      'mastercard': { icon: ImageIconPaymentMasterCard, description: 'MasterCard' },
      'ae': { icon: ImageIconPaymentAmex, description: 'AMEX' },
      'amex': { icon: ImageIconPaymentAmex, description: 'AMEX' },
      'diners': { icon: ImageIconPaymentDiners, description: 'Diners' },
      
      'grabpay': { icon: ImageIconPaymentGrabPay, description: 'GrabPay' },
      'favepay': { icon: ImageIconPaymentFavePay, description: 'FavePay' },
      'paylah': { icon: ImageIconPaymentPayLah, description: 'PayLah' },

      'default': { icon: ImageIconPaymentCash, description: 'Others' },
    };

    const displayedMethod = PaymentMethods.hasOwnProperty(paymentMethod.toLowerCase()) ? paymentMethod.toLowerCase() : 'default';
    const displayedInfo = displayType === 'description' ? displayType : 'icon';
    return PaymentMethods[displayedMethod as keyof typeof PaymentMethods][displayedInfo];
  };

  return (
    <div className={['pt-2.5', 'pb-4'].concat(simple ? [] : ['border', 'border-solid', 'border-gray', 'px-4', 'mb-4']).join(' ')}>
      {tabulation.map((tab, idx) => (
        <div key={idx} className="flex justify-between items-center mt-1.5">
          <BodyText text={tab.Description} variant={theme === 'dark' ? 'receipt-text-light' : 'receipt-text'} loading={false} />
          <BodyText text={format_currency(tab.Amount, currency)} variant={theme === 'dark' ? 'receipt-text-light' : 'receipt-text'} loading={loading} loadingClasses={'w-12'} />
        </div>
      ))}

      {loading || (payment && payment.Method) ? (
        <div className="flex justify-between items-center mt-1.5">
          <BodyText text="Payment mode" variant={theme === 'dark' ? 'receipt-text-light' : 'receipt-text'} loading={false} />
          <div className="text-[12px] md:text-lg uppercase flex items-center">
            { loading ? (
              null
            ) : (  
              <Image
                src={DisplayPaymentIcon(payment.Method, 'icon')}
                alt={DisplayPaymentIcon(payment.Method, 'description')}
                width={20}
                height={13}
                className='me-2 md:w-[26px]'
              />
            )}
            <BodyText text={loading ? '' : DisplayPaymentIcon(payment.Method, 'description')} variant={theme === 'dark' ? 'receipt-text-light' : 'receipt-text'} loading={loading} loadingClasses={'w-12'} />
          </div>
        </div>
      ) :(null)}
    </div>
  );
};
