import { gql } from '@apollo/client';

const mutation = gql`
  mutation mutation($DeviceId: ID!, $Amount: Float!, $Mobile: String) {
    RequestNewReceipt(DeviceId: $DeviceId, Amount: $Amount, Mobile: $Mobile) {
      Status
      StatusCode
      Message
      Id
      Data
    }
  }
`;

export default mutation;
