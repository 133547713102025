import { Image } from '..';
import dateFormat from 'dateformat';
import { ChitBlock } from '..';
import type { Chit } from '../../interfaces';
import ImageIconCalendar from '../../assets/icon-calender.png';

type ComponentProps = {
  date: number,
  chits: Chit[],
};

export default function ReceiptsDailyBlock({ date, chits}: ComponentProps) {
  return (
    <div className="receipts-block border border-solid border-gray pt-4 pb-3 px-2.5 mb-4">
      <div className="flex items-center justify-between">
      <div className="flex items-center">
        <Image
        src={ImageIconCalendar}
        alt="Calendar Icon"
        width={25}
        height={25}
        />
        <div className="flex items-center">
        <p className="text-cyan text-[34px] md:text-5xl font-bold ms-3 md:ms-5 me-1 leading-none">{dateFormat(new Date(date), 'd')}</p>
        <p className="text-cyan text-[12px] md:text-lg leading-tight">
          {dateFormat(new Date(date), 'ddd')}
          <br />
          {dateFormat(new Date(date), 'mmm yyyy')}
        </p>
        </div>
      </div>
      </div>
      
      <hr className="text-gray mt-2.5 mb-4" />
      {chits.map((chit, idx) =>
        <ChitBlock key={idx} chit={chit} url={['', 'receipts']} />
      )}
    </div>
  );
};
