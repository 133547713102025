import { BodyText, Image } from '..';
import type { GoogleMap } from '../../interfaces';
import ImageIconLocation from '../../assets/icon-location.png';

type ComponentProps = {
  address: string,
  googleMap: GoogleMap,
  loading: boolean,
};

export default function OutletLocationBlock({ address, googleMap, loading }: ComponentProps) {
  if (loading) {
    //googleMap.Embed = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d255278.15123323916!2d103.65923247520777!3d1.34374039272045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1767b42b8ec9%3A0x400f7acaedaa420!2sSingapore!5e0!3m2!1sen!2ssg!4v1699601069911!5m2!1sen!2ssg";
  }

  return (
    <div className="border border-solid border-gray">
      {googleMap.Embed && googleMap.Embed.length ? (
        <iframe title={address} src={googleMap.Embed} width="100%" height="300"></iframe>
      ) : (
        <div className="border-t border-solid border-gray py-20 p-2.5 md:px-4"></div>
      )}
      
      <div className="border-t border-solid border-gray pt-3 p-2.5 md:px-4">
        <div className="text-[12px] md:text-lg flex items-start md:items-center text-[#868686] leading-relaxed font-light">
          <Image
            src={ImageIconLocation}
            alt="Payment Method Icon"
            width={12}
            height={17}
            className='me-4 md:w-[18px]'
          />
          <a href={googleMap.Link} target="_blank" rel="noreferrer">
            <BodyText text={address} variant="receipt-text" loading={loading} loadingClasses={'w-72'} />
          </a>
        </div>
      </div>
    </div>
  );
};
