import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '.';
import type { Profile, InputProfile } from '../interfaces';
import { format_url } from '../utils/formatters';

type ComponentProps = {
  profile: Profile,
  saveProfile: (profile: InputProfile) => void,
  loading: boolean,
  errorCode: number
};

export default function ProfileForm({ profile, saveProfile, loading, errorCode }: ComponentProps) {
  const [data, setData] = useState((({ Name, Gender, AgeRange, BirthMonth, Email, OptIn }) => ({ Name, Gender, AgeRange, BirthMonth, Email, OptIn }))(profile));
  const [hasChanges, setHasChanges] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setData((({ Name, Gender, AgeRange, BirthMonth, Email, OptIn }) => ({ Name, Gender, AgeRange, BirthMonth, Email, OptIn }))(profile));
    setHasChanges(false);
  }, [profile]);

  const updateFormData = (e: React.FormEvent<HTMLInputElement|HTMLSelectElement>) => {
    setHasChanges(true);
    const fieldName = e.currentTarget.name;
    let fieldValue = e.currentTarget.value;

    if (fieldName === 'profileOptIn') {
      fieldValue = (data.OptIn === 'Y' ? 'N' : 'Y');
    }

    if (fieldName.indexOf('profile') === 0) {
      setData({
        ...data,
        [fieldName.slice(7)]: fieldValue.trim()
      });
    }
  };
  
  const SaveProfile = () => {
    if (!/^[\w\s.\-_(),]{3,100}$/.test(data.Name)) {
      console.log('Name invalid');
    }

    if (!/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/.test(data.Email)) {
      console.log('Email invalid');
    }

    saveProfile(data);
  };

  return (
    <main>
      <div className="input-group flex items-end mt-7">
        <label htmlFor="profileName" className="w-[90px] md:w-[150px] ps-1 border-solid border-[#d1d1d1] pb-1 md:text-2xl">Name</label>
        <input className="border-b border-solid border-[#d1d1d1] w-[calc(100%-90px)] md:w-[calc(100%-150px)] p-1.5 md:text-2xl" type="text" name="profileName" defaultValue={data.Name} onChange={updateFormData}/>
      </div>
      <div className="input-group flex items-end mt-4">
        <label htmlFor="userGender" className="w-[90px] md:w-[150px] ps-1 pb-1 md:text-2xl">Gender</label>
        <div className="radio-group me-4">
          <input type="radio" name='profileGender' value="M" checked={data.Gender === 'M' ? true : false} onChange={updateFormData} />
          <label htmlFor="maleGender" className="md:text-2xl ps-1.5">Male</label>
        </div>
        <div className="radio-group">
          <input type="radio" name='profileGender' value="F" checked={data.Gender === 'F' ? true : false} onChange={updateFormData} />
          <label htmlFor="profileGender[F]" className="md:text-2xl ps-1.5">Female</label>
        </div>
      </div>
      <div className="input-group flex items-end mt-4">
        <label htmlFor="age" className="w-[90px] md:w-[150px] ps-1 pb-1 md:text-2xl">Age</label>
        <select name="profileAgeRange" id="" className="border-b border-solid border-[#d1d1d1] w-[calc(100%-90px)] md:w-[calc(100%-150px)] p-1.5 md:text-2xl" value={data.AgeRange} onChange={updateFormData}>
          <option value="20 and below">20 and below</option>
          <option value="21 - 30">21 - 30</option>
          <option value="31 - 40">31 - 40</option>
          <option value="41 - 50">41 - 50</option>
          <option value="51 - 60">51 - 60</option>
          <option value="61 and above">61 and above</option>
        </select>
      </div>
      <div className="input-group flex items-end mt-4">
        <label htmlFor="birth-month" className="w-[90px] md:w-[150px] ps-1 pb-1 md:text-2xl">Birth Month</label>
        <select name="profileBirthMonth" id="" className="border-b border-solid border-[#d1d1d1] w-[calc(100%-90px)] md:w-[calc(100%-150px)] p-1.5 md:text-2xl" value={data.BirthMonth} onChange={updateFormData}>
          <option value="January">January</option>
          <option value="February">February</option>
          <option value="March">March</option>
          <option value="April">April</option>
          <option value="May">May</option>
          <option value="June">June</option>
          <option value="July">July</option>
          <option value="August">August</option>
          <option value="September">September</option>
          <option value="October">October</option>
          <option value="November">November</option>
          <option value="December">December</option>
        </select>
      </div>
      <div className="input-group flex items-end mt-4">
        <label htmlFor="email" className="w-[90px] md:w-[150px] ps-1 border-solid border-[#d1d1d1] pb-1 md:text-2xl">Email</label>
        <input type="email" name="profileEmail" defaultValue={data.Email} onChange={updateFormData} className="border-b border-solid border-[#d1d1d1] w-[calc(100%-90px)] md:w-[calc(100%-150px)] p-1.5 md:text-2xl" />
      </div>
      <div className="input-group flex items-start mt-7">
        <input className="mt-1.5 md:mt-1 me-2.5 scale-125 md:scale-100 ms-1.5" type="checkbox" name="profileOptIn" value="Y" checked={data.OptIn === 'Y' ? true : false} onChange={updateFormData} />
        <label htmlFor="recieveSMS" className="md:text-2xl font-light">I would like to receive marketing and promotional messages from Reseed via SMS, push notifications and messaging platforms like WhatsApp.</label>
      </div>

      {hasChanges ? (
        <div className="absolute left-0 bottom-[30px] md:bottom-[20px] w-full">
          <div className="container max-w-screen-md mx-auto flex gap-x-2.5">
            <div className="w-6/12">
              <Button className="bg-purple" text="Save" onClick={SaveProfile} />
            </div>
            <div className="w-6/12">
              <Button className="bg-[#a6a6a6]" text="Cancel" onClick={() => navigate(format_url(['/receipts']))} />
            </div>
          </div>
        </div>
      ) : (
        <div className="absolute left-0 bottom-[30px] md:bottom-[20px] w-full">
          <div className="container max-w-screen-md mx-auto flex gap-x-2.5">
            <div className="w-full">
              <Button className="bg-purple" text="Okay" onClick={() => navigate(format_url(['/receipts']))} />
            </div>
          </div>
        </div>
      )}

      {loading ? (
        <div className="mt-8 text-center">
          <span className="cus-loader"></span>
        </div>
      ) : (
        null
      )}   
    </main>    
  );
};
