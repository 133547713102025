import { gql } from '@apollo/client';

const mutation = gql`
  mutation mutation($DeviceId: ID!, $ReceiptId: ID!) {
    SaveNewReceipt(DeviceId: $DeviceId, ReceiptId: $ReceiptId) {
      Status
      StatusCode
      Message
      Id
      Data
    }
  }
`;

export default mutation;
