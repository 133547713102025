import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

export default function useGraphQLClient(endpoint: string, authType: string, authValue: string) {
  const httpLink = createHttpLink({
    uri: endpoint,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: authType === 'token' ? `Bearer ${authValue}` : `x-api-key ${authValue}`,
        'x-api-key': authType === 'api-key' ? `${authValue}` : '',
      }
    }
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });
};
