import { gql } from '@apollo/client';

const mutation = gql`
  mutation mutation($Mobile: String!, $Otp: String!) {
    RequestToken(Mobile: $Mobile, Otp: $Otp) {
      Status
      StatusCode
      Message
      Id
      Data
    }
  }
`;

export default mutation;
