import { Button, ReceiptsDailyBlock } from '..';
import type { Chit } from '../../interfaces';

type ComponentProps = {
  receipts: Chit[],
  hasMore: boolean,
  loadMore: () => void,
};

export default function ReceiptsListing({ receipts, hasMore, loadMore }: ComponentProps) {
  const getReceiptDateMidnightTimestamp = (ts: number) => {
    const tz_offset = 8 * 60 * 60;
    const seconds_per_day = 24 * 60 * 60;
    return Math.floor((ts + tz_offset) / seconds_per_day) * seconds_per_day * 1000;
  };

  const receiptDates = receipts.map(r => getReceiptDateMidnightTimestamp(r.Timestamp)).filter((v, i, s) => s.indexOf(v) === i);

  return (    
    <main>
      {receiptDates.map((date, idx) =>
        <ReceiptsDailyBlock key={idx} date={date} chits={ receipts.filter(r => getReceiptDateMidnightTimestamp(r.Timestamp) === date)} />
      )}

      {hasMore ? (
        <div className="load-more mb-2.5 w-6/12 mx-auto">
          <Button className="bg-[#a6a6a6]" text="Load More" onClick={loadMore} />
        </div>
      ) : (
        null
      )}
    </main>
  );
};
