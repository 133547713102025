import { LightboxActionContainerBlock, Button } from '..';
import type { Receipt } from '../../interfaces';

type ComponentProps = {
  statusCode: number,
  receipt: Receipt,
  remove: () => void,
  close: () => void
};

export default function LightboxReceiptRemove({ statusCode, receipt, remove, close }: ComponentProps) {
  const getText = (textType: string, statusCode: number) => {
    if (textType === 'header') {
      switch(statusCode) {
        case 200: return 'Receipt removed';
        case 404: return 'Error removing receipt';          
        case 500: return 'Error removing receipt';          
        default: return 'Remove this receipt?';
      }
    } else if (textType === 'instructions') {
      switch(statusCode) {
        case 102: return 'Removing, please wait.';
        case 200: return 'Receipt removed from your account.';
        case 404: return 'No such receipt.';
        case 500: return 'Unable to remove. Please try again.';
        default: return 'Once a receipt has been removed it will no longer be retrievable.';
      }
    }

    return '';
  };

  return (
    <LightboxActionContainerBlock 
      header={getText('header', statusCode)}
      instructions={getText('instructions', statusCode)} 
      loading={[102].indexOf(statusCode) >= 0 ? true : false}
      receipt={receipt} close={close} >
      
      {[0].indexOf(statusCode) >= 0 ? (
        <div className="flex">
          <div className="w-6/12 pe-2">
            <Button className="bg-[#a6a6a6]" text="Remove" onClick={remove} />
          </div>
          <div className="w-6/12 ps-2">
            <Button className="bg-purple" text="Cancel" onClick={close} />
          </div>
        </div>
      ) : (null)}

      {[0, 102].indexOf(statusCode) < 0 ? (
        <Button className="bg-purple" text="Okay" onClick={close} />
      ) : (null)}
    </LightboxActionContainerBlock>
  );
};
