import { Button, IconButton, Image } from '..';
import ImageIconSave from '../../assets/icon-save.png';
import ImageIconSaveMarked from '../../assets/icon-save-marked.png';

type ComponentProps = {
  actionType: string,
  actionList: {
    list?: () => void,
    save?: () => void,
    print?: () => void,
    download?: () => void,
    share?: () => void,
    remove?: () => void,
    back?: () => void,
    incorrect?: () => void,
  }
};

export default function ActionsBlock({ actionType, actionList }: ComponentProps) {
  /*
  const ShareApp = async () => {
    const ShareData = {
      title: 'Reseed - Digital receipts',
      text: 'Get digital receipts and save the environment!',
      url: "https://share.dev-reseed.click/"
    }
    try {
      await navigator.share(ShareData);
    } catch (err) {
      console.log({ Error: err });
    }
  };
  */
 
  const DoAction = (actionName: string) => {
    const action = actionList[actionName as keyof ComponentProps['actionList']];
    if (typeof action !== 'undefined') {
      action();
    }
  };

  return (
    <div className="bg-white fixed bottom-0 left-0 shadow-normal p-4 w-full">
      <div className="container max-w-screen-md mx-auto px-0 md:px-4">
        {actionType === 'view' ? (<>
          <div className="flex gap-x-2.5">
            <div className="w-6/12">
              <Button className="bg-purple" text="Download" onClick={() => DoAction('download')} />
            </div>
            <div className="w-6/12">
              <Button className="bg-[#a6a6a6]" text="Back" onClick={() => DoAction('list')} />
            </div>
          </div>
          <div className="w-full text-center mt-2.5">
            <button className="text-purple underline text-[12px] md:text-lg" onClick={() => DoAction('remove')}>Remove this receipt</button>
          </div>
        </>) : (null)}

        {actionType === 'new' ? (<>
          <div className="flex gap-x-2.5">
            <div className="w-6/12">
              <Button className="bg-purple" text="Save" onClick={() => DoAction('save')} />
            </div>
            <div className="w-6/12">
              <Button className="bg-[#a6a6a6]" text="Print" onClick={() => DoAction('print')} />
            </div>
          </div>
          <div className="w-full text-center mt-2.5">
            <button className="text-purple underline text-[12px] md:text-lg" onClick={() => DoAction('incorrect')}>Not my receipt</button>
          </div>
        </>) : (null)}

        {actionType === 'printed' ? (<>
          <div className="flex gap-x-2.5">
            <div className="w-full">
              <IconButton className="bg-purple" text="Save" imgSrc={ImageIconSave} onClick={() => DoAction('save')} />
            </div>
          </div>
          <div className="w-full text-center mt-2.5">
            <button className="text-purple underline text-[12px] md:text-lg" onClick={() => DoAction('incorrect')}>Not my receipt</button>
          </div>
        </>) : (null)}

        {actionType === 'saved' ? (<>
          <div className="w-full text-center mb-2.5">
            <div className="text-purple text-[12px] md:text-lg">
              <Image
                src={ImageIconSaveMarked}
                alt="Arrow Icon"
                width={9}
                height={12}
                className="inline-block me-2"
              />
              Receipt saved
            </div>
          </div>
          <div className="flex gap-x-2.5">
            <div className="w-full">
              <Button className="bg-purple" text="View receipts" onClick={() => DoAction('list')} />
            </div>
          </div>          
        </>) : (null)}

        {actionType === 'saving' ? (<>
          <div className="w-full text-center my-3">
            <div className="text-purple text-[12px] md:text-lg">
              <span className={'!text-[12px] cus-loader mr-4 mt-2'}></span>
              Saving receipt to your account
            </div>
          </div>
        </>) : (null)}
      </div>
    </div>
  );
};
