import { ReactNode } from 'react';
import { Image, Logo } from '..';
import ImageBtnClose from '../../assets/img-btn-close.png';

type ComponentProps = {
  children: ReactNode,
  close: () => void
};

export default function ModalConfirmation({ children, close } : ComponentProps) {
  return (
    <div className="top-0 left-0 bg-black fixed h-screen w-screen z-10 pt-5">
      <button type="button" className="absolute right-4 md:right-8 top-5" onClick={close}>
          <Image
            src={ImageBtnClose}
            alt="Close Icon"
            width={14}
            height={15}
            className='mx-auto'
          />
        </button>
      <div className="container max-w-screen-md mx-auto">
        <div className="text-center">
          <Logo />
        </div>
        {children}
      </div>
    </div>
  );
};
