import { BodyText } from '..';

type ComponentProps = {
  date: string,
  id: string,
  loading: boolean,
};

export default function ReceiptMetaBlock({ date, id, loading }: ComponentProps) {
  return (
    <div className="flex justify-between items-center">
      <BodyText text={ loading ? '' : date } variant="receipt-text" loading={loading} loadingClasses={'w-36'} />
      <BodyText text={ loading ? '' : ['#', id].join('')} variant="receipt-text" loading={loading} loadingClasses={'w-24'} />
    </div>
  );
};
