import { BodyText, Image } from '..';

type ComponentProps = {
  name: string,
  location: string,
  logo: string,
  loading: boolean,
  theme?: string
};

export default function OutletInfoBlock({ name, location, logo, loading, theme = 'light' }: ComponentProps) {
  return (
    <div className="flex items-center mt-3 mb-6">
      <Image
        src={['https://static.dev-reseed.click', 'entities', loading ? 'dummy.png' : logo].join('/')}
        alt={name}
        width={70}
        height={70}
        className="rounded-full md:w-[100px]"
      />
      <div className="name ms-5">
        <BodyText text={name} variant={theme === 'dark' ? 'receipt-outlet-name-light' : 'receipt-outlet-name'} loading={loading} loadingClasses={'w-36'} />
        <BodyText text={location} variant={theme === 'dark' ? 'receipt-text-light' : 'receipt-text'} loading={loading} loadingClasses={'w-36'} />
      </div>
    </div>
  );
};
