import { Link } from 'react-router-dom';
import { BodyText, Image } from '..';
import type { Chit } from '../../interfaces';
import { format_currency, format_url } from '../../utils/formatters';

type ComponentProps = {
  chit: Chit,
  url: string[]
};

export default function ChitBlock({ chit, url }: ComponentProps) {
  return (
    <Link to={format_url(url.concat(chit.ReceiptId))} className="flex items-start justify-between mb-2">
      <div className="flex items-start">
      <Image
        src={['https://static.dev-reseed.click', 'entities', chit.Logo].join('/')}
        alt={chit.StoreName}
        width={25}
        height={25}
        className="rounded-full md:w-[40px]"
      />
      <div className="ms-3">
        <BodyText text={chit.StoreName} variant="outlet-name" />
        <BodyText text={chit.LocationName} variant="outlet-location" />
      </div>
      </div>
      <BodyText text={format_currency(chit.Total, chit.Currency)} variant="receipt-text" />
    </Link>
  );
};
